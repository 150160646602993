

.app__chef-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 5rem;
}

.app__chef-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.app__chef-content_quote img {
    width: 23px;
    height: 20px;
    margin: 0 1rem 1rem 0;
}

.app__chef-sign {
    width: 100%;
    margin-top: 3rem;
}

.app__chef-sign p:nth-child(2) {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
}

.app__chef-sign img {
    width: 250px;
    margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
    .app__chef-sign img {
        width: 370px;
    }
}

@media screen and (max-width: 450px) {
    .app__chef-sign img {
        width: 80%;
    }
}

.video__observer-styles {
    width: 480px;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.video__play-styles {
    position: absolute;
    //bottom: 1.5rem;
    cursor: pointer;
    //left: 1rem;
    display: flex;
    gap: 5rem;
    padding: 0.75rem;

}


.video__text-styles {
    background: none;
    color: #fff;
    border: none;
    font-size: 3.5rem;
    line-height: 2rem;
}

