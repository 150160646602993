/*body {*/
/*  overflow-y: hidden;*/
/*}*/



/*::-webkit-scrollbar {*/
/*  !*display: none;*!*/

/*}*/


/*::-webkit-scrollbar-track {*/
/*  background-color: #000;*/
/*}*/




body::-webkit-scrollbar {
  width: 0.4em;
  /*background: black;*/
  background: var(--color-black);
  z-index: 5;
}
body::-webkit-scrollbar-thumb {
  /*background: #c0392b;*/
  background: var(--color-golden);
  height:20px;
  border-radius: 20px;
  z-index: 5;
}

body::-webkit-scrollbar-track-piece
{
  display:none;
}


.splash__button {
  background-color: black !important;
  border: 2px solid var(--color-golden) !important;
  color: var(--color-golden) !important;
}

.splash__button:hover {
  background-color: var(--color-golden) !important;
  border: 2px solid black !important;
  color: black !important;
}



.video__styles {
  width: 350px;




  @media screen and (max-width: 450px) {
    width: 300px;
  }
}


.stars__canvas-styles {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /*z-index: -1;*/
  z-index: 2;
  /*background-color: #000000;*/
}

.section__padding {
  //padding: 4rem 6rem;
  /*padding: 4rem 0;*/
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}



.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
  /*min-width: 100vw;*/


}

.app__wrapper2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
  /*min-width: 100vw;*/

  @media screen and (max-width: 1150px) {
    min-height: 0;
  }
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  //align-items: flex-start;
  align-items: center;
  flex-direction: column;
  //margin-top: 40px;

  @media screen and (max-width: 1150px) {
    align-items: center;
    text-align: center;
    margin-top: 0;
  }

  @media(max-width: 767px) {
    align-items: center;
    text-align: center;
    margin-top: 0;
  }

  @media(max-width: 450px) {
      //justify-content: flex-end;
    margin-top: 0;
  }
}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1150px) {
    width: 100%;
    margin: 3rem 0 0 0 !important;
  }

  @media(max-width: 767px) {
    width: 100%;
    margin: 3rem 0 0 0 !important;
  }

  @media(max-width: 450px) {
    margin: 2rem 0 0 0 !important;
  }

  /*margin-left: 2rem;*/
}

.app__wrapper_img-reverse {
  //justify-content: flex-start;
  //margin: 2rem;
  justify-content: center;
  //margin-right: 0;

  @media screen and (max-width: 1150px) {
    justify-content: center;
  }

  @media(max-width: 767px) {
    justify-content: center;
  }

}

.app__wrapper_img img {
  width: 80%;
}

.custom__button {
  background-color: var(--color-crimson);
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;

  //display: -webkit-box;
  //overflow: hidden;
  //-webkit-box-orient: vertical;
  //-webkit-line-clamp: 11;

  @media screen and (max-width: 1150px) {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }

  @media screen and (max-width: 650px) {
    font-size: 16px;
  }


}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  //font-size: 64px;
  font-size: 54px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  text-align: center;
}

.spoon__img {
  width: 25px;
  margin-top: 5px;
  color: var(--color-golden);
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .spoon__img {
    width: 80px;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper2 {
    flex-direction: column;
  }

  .headtext__cormorant {
    font-size: 50px;
  }

  .section__padding {
    padding: 2rem 2rem 3rem 2rem;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 360px;

  }

  .custom__mobile-header {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 2rem 2rem 3rem 2rem;
  }

  .headtext__cormorant {
    font-size: 45px;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 2rem 2rem 3rem 2rem;
  }

  .p__cormorant {
    font-size: 21px;
  }

  .headtext__cormorant {
    font-size: 40px;
  }
}

@media screen and (max-width: 450px) {
  .section__padding {
    padding: 2rem 2rem 3rem 2rem;
  }

  .p__opensans {
    font-size: 15px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 34px;
    line-height: 70px;
  }

  .app__wrapper_img img {
    width: 300px;
  }
}

@media screen and (max-width: 375px) {
  .app__wrapper_img img {
    width: 280px;
  }
}

.subheading__m {
  text-align: center;
}

.app__chef-content {
  text-align: center;
}

.app__chef-sign {
  text-align: center;
}

.app__gallery-content {
  align-items: center;
}

.app__header-h1 {
  text-align: center;
}

.gallery__direction {
  flex-direction: row;

  //@media screen and (max-width: 1151px) {
  //  flex-direction: column ;
  //}
}

@media screen and (min-width: 1151px) {
  .reverse {
    flex-direction: row-reverse;
  }
}


.app__chef-content {
  @media screen and (max-width: 1150px) {
    margin-top: 3rem !important;
  }
}


.client__mobile {
  @media screen and (min-width: 1151px) {
    display: none;
  }
}


.client__desktop {
  @media screen and (max-width: 1150px) {
    display: none;
  }
}


.app__bg {
  @media screen and (min-width: 1151px) {
  background: url('./assets/bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
  }

  @media screen and (max-width: 1150px) {
    background: url('./assets/bg.png') no-repeat center;
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
  }
}

.scroll__margin {
  scroll-margin-top: 6.3rem;

  @media screen and (max-width: 650px) {
    scroll-margin-top: 5.7rem;
  }
}


.diamond__about-size {
  width: 350px;
  height: 350px;

  @media screen and (max-width: 450px) {
    width: 300px;
    height: 300px;
  }
}

.one-section__display {
  display: none;
}

.leva-c-kWgxhW-czBHep-hideTitleBar-false {
  display: none !important;
}


.read-or-hide{
  color: rgb(192,192,192);
  cursor: pointer;
}
