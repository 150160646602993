#home::-webkit-scrollbar {
    width: 0.7em;
    background: black;

}
#home::-webkit-scrollbar-thumb {
    background: #c0392b;
    height:30px;
}

#home::-webkit-scrollbar-track-piece
{
    display:none;
}


.home-bg-video {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -3;
    /*background: url("../video/ktmanagers_homepage-2.jpg") no-repeat;*/
    background-size: cover;
}

.home-overlay {
    background-color: rgba(0,0,0,0.85);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -3;
}







.app__header {
    //background-color: var(--color-black);
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 90px;

    @media screen and (max-width: 1150px) {
        font-size: 55px;
        line-height: 87px;
    }

    @media screen and (max-width: 767px) {
        font-size: 55px;
        line-height: 87px;
    }
}

//.subheading__m {
//    @media screen and (max-width: 550px) {
//        display: none;
//    }
//}

.app__header-img img {
    width: 80%;
}

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

//@media screen and (max-width: 1150px) {
//    .app__header-h1 {
//        font-size: 90px;
//    }
//}

//@media screen and (max-width: 650px) {
//    .app__header-h1 {
//        font-size: 70px;
//        line-height: 87px;
//    }
//}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }
}
