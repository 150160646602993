.app__gallery {


    background: var(--color-black);
    padding: 4rem 6rem 4rem 6rem;
    /*padding: 4rem 0 4rem 0;*/
}

.app__gallery-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    min-width: 500px;
    //padding-right: 2rem;
}

.app__gallery-content button {
    margin-top: 1rem;
}

.app__gallery-images {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;
}

.app__gallery-images_container {
    display: flex;
    flex-direction: row;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.app__gallery-images_container::-webkit-scrollbar {
    display: none;
}

.app__gallery-images_card {
    position: relative;
    min-width: 301px;
    height: 447px;
    margin-right: 2rem;
}

.gallery__image-icon {
    position: absolute;
    color: #fff;
    font-size: 2rem;
    opacity: 0;
    transition: .5s ease;
    cursor: pointer;
}

.app__gallery-images_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: .5s ease;
}

.app__gallery-images_card:hover img {
    opacity: .35;
}

.app__gallery-images_card:hover .gallery__image-icon {
    opacity: 1;
}

.app__gallery-images_arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1rem;
    position: absolute;
    bottom: 5%;
}

.gallery__arrow-icon {
    color: var(--color-golden);
    font-size: 2rem;
    cursor: pointer;
    background: var(--color-black);
    border-radius: 5px;
}

.gallery__arrow-icon:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__gallery-content button {
        margin-top: 2rem;
    }

    .app__gallery-content {
        min-width: 1000px;
        padding-right: 4rem;
    }

    .app__gallery-images_card {
        min-width: 400px;
        height: 547px;
    }
}

@media screen and (max-width: 1150px) {
    .app__gallery {
        flex-direction: column;
    }

    .app__gallery-images {
        max-width: 100%;
        margin: 5rem 0;
    }
}

@media screen and (max-width: 850px) {
    .app__gallery {
        padding: 4rem 0 4rem 4rem;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
        padding: 4rem 0 4rem 2rem;
    }

    .app__gallery-content {
        min-width: 100%;
    }

    .app__gallery-images_card {
        min-width: 240px;
        height: 320px;
    }
}



#lightbox {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*optional bg color*/
    background-color: rgba(0, 0, 0, 0.82);
    /*the following isn't necessary but will help center everything once we add buttons*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#lightbox-img {
    height: 80vh;
    max-width: 80vw;
    object-fit: cover;

    @media screen and (max-width: 700px) {
        height: 60vh;
        max-width: 70vw;
    }

    @media screen and (max-width: 550px) {
        height: 50vh;
        max-width: 70vw;
    }

    @media screen and (max-width: 450px) {
        height: 50vh;
        max-width: 80vw;
    }

    @media screen and (max-width: 350px) {
        height: 50vh;
        max-width: 80vw;
    }
}

.lightbox-button {
    color: var(--color-golden);
    border: 2px solid var(--color-golden);
    background-color: black;
    font-size: 2rem;
    cursor: pointer;

    margin: 10px;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (max-width: 700px) {
        padding-left: 2.5px;
        padding-right: 2.5px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media screen and (max-width: 450px) {
        padding-left: 2px;
        padding-right: 2px;
        margin: 5px;
        font-size: 1.1rem;
        padding-top: 30px;
        padding-bottom: 30px;
    }


    @media screen and (max-width: 350px) {
        padding-left: 2px;
        padding-right: 2px;
        margin: 2px;
        font-size: 1rem;
    }
}
