#splash {
    z-index: 1000;
}

//#splash::-webkit-scrollbar  {
// display: none;
//}

.scroll-bar__styles {
    ::-webkit-scrollbar {
        display: none;
    }
}


#splashscreen {
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    width:100%;
    background-color:white;
}

.preloader {
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.status {
    background-image: url("../../assets/wine-preloader-2.gif");
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -35px;
    margin-left: -35px;
}

.video-bg {
    width: 100%;
//height: 100vh;
    font-family: Impact, Haettenschweiler,'Arial Narrow Bold', sans-serif;


video {
    width: 100%;
    height: 100%;
//height: 100vh;
    object-fit: cover;
    z-index: 1;
}
}

.video-text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*color: var(--secondary-color);*/
    z-index: 500;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(51, 48, 20, 0.8);
//background-color: #edf2f8cc;
    background-color: rgba(0,0,0,.4);
}

//@keyframes fadeInOutAnimation {
//    0% {
//        opacity: 0;
//    }
//    100% {
//        opacity: 1;
//    }
//}

//.transition__effect-on {
//    opacity: 1;
//}

//.transition__effect-off {
//    animation: fadeInOutAnimation 1.5s;
//    //animation-direction: fade-out();
//    animation-timing-function: ease-out;
//}

.fade-2 {
    opacity: 0;
}

.fade-out-2 {
    animation: fadeOut ease .5s;
    -webkit-animation: fadeOut ease .5s;
    -moz-animation: fadeOut ease .5s;
    -o-animation: fadeOut ease .5s;
    -ms-animation: fadeOut ease .5s;
}

.fade-out {
    animation: fadeOut ease 3.5s;
    -webkit-animation: fadeOut ease 3.5s;
    -moz-animation: fadeOut ease 3.5s;
    -o-animation: fadeOut ease 3.5s;
    -ms-animation: fadeOut ease 3.5s;
}

//.fade-out {
//    animation: fadeOut ease 5s;
//    -webkit-animation: fadeOut ease 5s;
//    -moz-animation: fadeOut ease 5s;
//    -o-animation: fadeOut ease 5s;
//    -ms-animation: fadeOut ease 5s;
//}
@keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
