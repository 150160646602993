.app__aboutus {
    position: relative;
    height: 800px ;

    @media screen and (max-width: 900px) {
        height: 100%;
    }
}

.app__aboutus-xl {
    position: relative;
    height: 1000px ;

    @media screen and (max-width: 900px) {
        height: 100%;
    }
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}

.app__aboutus-content_about {
    flex: 1;
    display: flex;
    //justify-content: flex-end;
    justify-content: center;
    //align-items: flex-end;
    align-items: center;
    flex-direction: column;

    text-align: right;

    @media screen and (max-width: 900px) {
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 767px) {
        justify-content: center;
        align-items: center;
    }
}

.app__aboutus-content_knife {
    margin: 2rem 14rem;
    height: 700px;

    @media screen and (max-width: 900px) {
        height: 500px;
    }

    @media screen and (max-width: 767px) {
        height: 300px;
    }
}

.app__aboutus-content_knife img {
    height: 910px;
}

.app__aboutus-content_history {
    flex: 1;
    display: flex;
    //justify-content: flex-end;
    justify-content: center;
    //align-items: flex-end;
    align-items: center;
    flex-direction: column;

    text-align: center;

    @media screen and (max-width: 900px) {
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 767px) {
        justify-content: center;
        align-items: center;
    }
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
    margin: 2rem 0;
    color: var(--color-grey);
}

@media screen and (min-width: 2000px) {
    .app__aboutus-content_knife img {
        height: 1110px;
    }

    .app__aboutus-content_about p,
    .app__aboutus-content_history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_knife {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        //width: 80%;
        //width: 320px;
        //height: 320px;

        width: 270px;
        height: 270px;
    }
}

@media screen and (max-width: 375px) {
    .app__aboutus-overlay img {
        width: 270px;
        height: 270px;
    }
}
